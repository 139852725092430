import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col, Button } from 'react-bootstrap'
import ReactGA from 'react-ga'
import { Link } from 'react-router-dom'

import { SIZES } from '../../styles/theme';

import logo from '../../images/logo.png'
import BgImg from '../../images/backdrops/entrance.jpg'
import routes from '../../routes';

const Page = styled.div`

  min-height: 100vh;
  background-image: linear-gradient(
      rgba(82, 92, 163, 1),
      rgba(82, 92, 163, 1)
    ),
    url(${BgImg});
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-size: cover;
  color: #FFFFFF;

  ${SIZES.mobile} {
    font-size: 75%;
    background-size: cover;
    background-position: 10% 20%;
  }
  .content {
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0)
    );
    padding: 3em;
    text-align: center;
    ${SIZES.mobile} {
      padding: 2em;
    }
    img {
      width:50%;
      display: block;
      margin: 0 auto;
    }
    .divider {
      border-bottom: 2px solid #C3A97A;
      margin: 48px 32px;
      ${SIZES.mobile} {
        margin-bottom: 0;
      }
    }
  }
  .details {
    font-size: 1.1em;
    text-align: center;
    margin-bottom: 12px;
    p {
      margin: 4px;
    }
  }
  h6 {
    color: #C3A97A;
    text-align: center;
    margin: 16px;
    font-weight: 600;
    font-style: italic;
    ${SIZES.mobile} {
      margin: 8px;
      color: #FFFFFF;
    }
  }
  a,
  button {
    background: #525CA3;
    width: 180px;
    margin-top: 36px;
    font-size: 1.2em;
    color: #F6F6F9;
    font-weight: 500;
    padding: 8px 24px;
    border-radius: 56px;
    font-style: italic ;

    &:hover {
      color: #FFF5E2;
      background:#0A2A53;
    }
  }
`

export default function PreRegisterPage() {

  useEffect(() => {
    ReactGA.pageview('/pre-register-page')
  }, [])

  return (
    <Page>
      <div className="content">
        <Row noGutters>
          <Col md={{ span: 4, offset: 4 }}>
            <img src={logo} alt="sanofi"/>
            <Button variant="default" as={Link} to={routes.register}>
              Register
            </Button>
          </Col>
        </Row>
        <div className="divider"></div>
      </div>

      <Row noGutters>
        <Col className="details" md={{ span: 4, offset: 1 }}>
          <strong>
            <em>Sanofi-Aventis Singapore Pte. Ltd.</em>
          </strong>
          <p>38 Beach Road #18-11, Singapore 189767</p>
          <p>Tel: 65 6226 3836 Fax: 65 6535 5836</p>
          <p>www.sanofi.com.sg</p>
        </Col>
        <Col className="details" md={6}>
          <strong>
            <em>Sanofi-Aventis Singapore Pte. Ltd.</em>
          </strong>
          <p>Unit TB-18-1, Level 18, Tower B, Plaza 33,<br/>
          1, Jalan Kemajuan, Seksyen 13, 46200 Petaling Jaya, Selangor</p>
          <p>Tel: +603 7651 0800 Fax: +603 7651 0801 / 0802</p>
          <p>www.sanofi.com.my</p>
        </Col>
      </Row>
      <h6>For Healthcare professionals only.</h6>
    </Page>
  )
}