import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col, Button, Form } from 'react-bootstrap'
import ReactGA from 'react-ga'
import { Link, useHistory } from 'react-router-dom'
import SelectSearch from 'react-select-search'
import PhoneInput from 'react-phone-input-2'

import { SIZES } from '../../styles/theme'

import logo from '../../images/logo.png'
import clexaneLogo from '../../images/registration/ClexaneLogo.png'
import BgImg from '../../images/backdrops/entrance.jpg'
import countryList from '../../constants/countryList'
import Axios from 'axios'
import config from '../../config'
import { toast } from 'react-toastify'
import routes from '../../routes'

const Page = styled.div`
  min-height: 100vh;
  background-image: linear-gradient(
      rgba(82, 92, 163, 0.94),
      rgba(82, 92, 163, 0.94)
    ),
    url(${BgImg});
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-size: cover;
  background-position:left;
  color: #fff5e2;
  padding: 2.5em 0;
  ${SIZES.mobile} {
    padding: 0;
    padding-bottom: 2.5em;
    font-size: 1.1em;
    color: #C3A97A;
  }
  .divider {
    border-bottom: 2px solid #C3A97A;
    margin: 24px auto;
    width: 80%;
  }
  header {
    text-align: center;
    font-style: italic;
    h3 {
      font-weight: 600;
    }
  }
  .row {
    .col-md-4 {
      img {
        width: 50%;
        display:block;
        margin: 8px auto;
      }
      h6 {
        color: #FFFFFF;
        text-align: center;
        margin: 16px;
        font-weight: 600;
        font-style: italic;
        ${SIZES.mobile} {
          margin: 8px;
        }
      }
      .details {
        color: #FFFFFF;
        text-align: center;
        margin-bottom: 8px;
        p {
          font-size: 0.9em;
          margin: 12px;
        }
      }
      .clause {
        font-size:0.9em;
        color: #FFFFFF;
        padding: 0 32px;
        ol {
          padding-inline-start: 20px;
        }
      }
    }
    .register-form {
      border-radius: 48px;
      background: #fcfbf9;
      background: linear-gradient(
        180deg,
        rgba(252, 251, 249, 1) 0%,
        rgba(88, 97, 162, 0.79) 100%
      );
      border: 1px solid #525CA3;
      padding: 2.5em;

      .form-group {
        width: 100%;
        margin-bottom: 8px;
        label {
          color: #2f2651;
          font-weight: 600;
          font-style: italic;
          padding: 0 8px;
          padding-right: 0;
          font-size: 13px;
        }
        input[type='tel'],
        .form-input {
          display: block;
          color: #2f2651;
          letter-spacing: 1px;
          width: 100%;
          background: transparent;
          border: 1px solid #E3D5BA;
          padding: 4px 16px;
          border-radius: 25px;
          outline: none;
        }
        .react-tel-input {
          color: #2f2651;
          input[type='tel'] {
            padding-left: 56px;
            height: 35px;
            box-shadow: none;
          }
          .country-list {
            margin: 0;
          }
          .flag-dropdown {
            background: transparent;
            border: none;
            padding-left: 16px;
            .selected-flag {
              background: transparent;
            }
          }
        }
      }
    }
  }
  button.submit-btn {
    background: #525CA3;
    width: 75%;
    color: #2f2651;
    font-weight: 500;
    font-style: italic;
    border: 1px solid #0A2A53;
    border-radius: 56px;
    display: flex;
    justify-content: space-between;
    padding: 0px 24px;
    margin: 0 auto;
    font-size: 0.8em;
    outline: none;
    box-shadow: none;
    ${SIZES.mobile} {
      width: 100%;
      padding: 4px 24px;
    }
    &:disabled {
      cursor: not-allowed;
      background: #c3c3c3;
      border: solid 1px #707070;
    }
    :not(:disabled):hover {
      background: #0A2A53;
      border: 1px solid #0A2A53;
      color: #fff5e2;
    }
    div {
      line-height: 32px;
    }
    div.arrow {
      line-height: 24px;
      font-size: 1.5em;
      align-self: flex-end;
    }
  }
  /* searchable select input */
  .select-search {
    width: 100%;
    position: relative;
    box-sizing: border-box;
  }
  .select-search *,
  .select-search *::after,
  .select-search *::before {
    box-sizing: inherit;
  }
  .select-search__value {
    position: relative;
    z-index: 1;
  }
  .select-search__value::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
  }
  .select-search__input {
    color: #2f2651;
    background: transparent;
    border: 1px solid #E3D5BA;
    padding: 4px 16px;
    border-radius: 25px;
    display: block;
    width: 100%;
    outline: none;
    text-overflow: ellipsis;
    -webkit-appearance: none;
    cursor: pointer;
  }
  .select-search__input:focus {
    cursor: initial;
  }
  .select-search__options {
    list-style: none;
    padding-right: 8px;
    padding-left: 8px;
  }
  .select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
  }
  .select-search__option,
  .select-search__not-found {
    display: block;
    min-height: 32px;
    width: 100%;
    padding: 0 8px;
    background: #fff;
    border: none;
    outline: none;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
  }
  .select-search__option.is-selected {
    background: #525CA3;
    color: #fff;
  }
  .select-search .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
  }
  .select-search__option.is-highlighted,
  .select-search__option:not(.is-selected):hover {
    background: #8c93ca;
  }
  .select-search .select-search__select {
    background: #fff;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 300px;
  }
  .select-search__not-found {
    height: auto;
    padding: 16px;
  }
`

export default function RegisterFormPage() {
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [country, setCountry] = useState('Malaysia')
  const [nationalId, setNationalId] = useState('')
  const [phone, setPhone] = useState('60')
  const [mmcNum, setMmcNum] = useState('')
  const [password, setPassword] = useState('')


  useEffect(() => {
    ReactGA.pageview('/register-form-page')
  }, [])

  const handleEmailInput = (e) => {
    setEmail(e.target.value.toLowerCase())
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    const options = {
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      email,
      country,
      national_id: nationalId.trim(),
      phone,
      mmc_number: mmcNum,
      password: password.trim(),
    }
    Axios.post(`${config.apiUrl}/api/v1/register`, options)
      .then(() => {
        setLoading(false)
        history.push(routes.postRegister)
      })
      .catch((err) => {
        setLoading(false)
        toast(err.response.data.message)
      })
  }

  const hasEmptyField = !(
    firstName.length &&
    lastName.length &&
    email.length &&
    country.length &&
    nationalId.length &&
    phone.length >= 7 &&
    mmcNum.length &&
    password.length >= 8
  )

  return (
    <Page>
      <header className="px-5 pt-5 pb-3 py-md-0">
        <h3>Registration</h3>
        <p>Take a moment to fill up the registration form</p>
      </header>
      <Row noGutters>
        <Col className="d-none d-md-block" md={4}>
          <img src={logo} alt="sanofi" />
          <img src={clexaneLogo} alt="clexane" />
        </Col>
        <Col md={4}>
          <Form onSubmit={handleSubmit}>
            <Row className="register-form py-3" noGutters>
              <Form.Group>
                <label>First Name*</label>
                <input
                  className="form-input"
                  required
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <label>Last Name*</label>
                <input
                  className="form-input"
                  required
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <label>E-mail*</label>
                <input
                  className="form-input"
                  required
                  type="email"
                  value={email}
                  onChange={handleEmailInput}
                />
              </Form.Group>
              <Form.Group>
                <label>Country/Region*</label>
                <SelectSearch
                  name="country"
                  value={country}
                  onChange={(country) => setCountry(country)}
                  options={countryList}
                  search
                  autoComplete="off"
                />
              </Form.Group>
              <Form.Group>
                <label>National ID <small>(For Malaysian HCPs only, for CME accreditation purposes. Example: 901010-14-5286)</small></label>
                <input
                  className="form-input"
                  type="text"
                  value={nationalId}
                  onChange={(e) => setNationalId(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <label>MMC/MCR Number <br /><small>(Example: 99999 for MY and M99999H for SG)</small></label>
                <input
                  className="form-input"
                  type="text"
                  value={mmcNum}
                  onChange={(e) => setMmcNum(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <label>Mobile Number* (with country code)</label>
                <PhoneInput
                  inputProps={{ required: true }}
                  className="form-input"
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                  placeholder=""
                />
              </Form.Group>
              <Form.Group>
                <label>Create A Password* (min. 8 characters)</label>
                <input
                  className="form-input"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <div className="w-100 ">
                {hasEmptyField && (
                  <p className="text-danger text-right">
                    <em>
                      <small>Please fill up all fields</small>
                    </em>
                  </p>
                )}
                <Button
                  type="submit"
                  className="submit-btn"
                  variant="default"
                  disabled={hasEmptyField || loading}
                  onSubmit={handleSubmit}
                >
                  <div>{loading ? 'Loading...' : 'Submit'}</div>
                  <div className="arrow">⇀</div>
                </Button>
              </div>
            </Row>
          </Form>
        </Col>
        <div className="divider d-block d-md-none"></div>
        <Col md={4}>
          <Row noGutters>
            <Col className="details" xs={12}>
              <strong>
                <em>Sanofi-Aventis Singapore Pte. Ltd.</em>
              </strong>
              <p>38 Beach Road #18-11, Singapore 189767</p>
              <p>Tel: 65 6226 3836 Fax: 65 6535 5836</p>
              <p>www.sanofi.com.sg</p>
            </Col>
            <Col className="details" xs={12}>
              <strong>
                <em>Sanofi-Aventis Singapore Pte. Ltd.</em>
              </strong>
              <p>Unit TB-18-1, Level 18, Tower B, Plaza 33,<br/>
              1, Jalan Kemajuan, Seksyen 13, 46200 Petaling Jaya, Selangor</p>
              <p>Tel: +603 7651 0800 Fax: +603 7651 0801 / 0802</p>
              <p>www.sanofi.com.my</p>
            </Col>
          </Row>
          <h6>For Healthcare professionals only.</h6>
          <div className="clause">
            By completing and submitting this form to register for the Event:
            <small>
              <ol type="a">
                <li>
                  I acknowledge and agree that I have read, understood and agreed to the Terms of Use, Personal Data Protection Notice & Cookie Policy.<br />
                  *You may view the Terms of Use, Personal Data Protection Notice & Cookie Policy by copying and pasting the link in your web browser.<br />
                  <a href="http://surl.sanofi.com/sgwebinar" target="_blank">http://surl.sanofi.com/sgwebinar</a> for SG and <a href="http://surl.sanofi.com/myzoomtc" target="_blank">http://surl.sanofi.com/myzoomtc</a> for MY 
                </li>
                <li>
                  I consent to the collection, use and disclosure of my personal data by Sanofi in the manner described in Sanofi’s Personal Data Protection Notice.
                </li>
                <li>
                  I understand that Sanofi will use the information I have provided in this form to be in touch with me and to provide information and updates on the event.
                </li>
              </ol>
            </small>
          </div>
        </Col>
      </Row>
    </Page>
  )
}
