import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import config from '../config'
import routes from '../routes'
import { COLORS, SIZES } from '../styles/theme'
import { Row, Col, Button } from 'react-bootstrap'
import logo from '../images/logo.png'

const Page = styled.div`
  background-size: cover;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
  text-align: center;
  font-style: italic;
  color: #2F2651;
  font-size: 1.2em;
  background: #F6F6F9;
  padding: 1em;
  ${SIZES.mobile} {
    font-size: 0.9em;
    img {
      width: 50%;
    }
  }
  .details {
    text-align: center;
    margin-bottom: 12px;
    p {
      font-style: normal;
      font-size: 0.9em;
      margin: 12px;
    }
  }
  a,
  button {
    background: ${COLORS.primary};
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 48px;
    font-size: 1.1em;
    color: #F6F6F9;
    font-weight: 500;
    padding: 10px 35px;
    border-radius: 56px;
    font-style: italic ;

    &:hover {
      color: #F6F6F9;
    }
    div.arrow {
      line-height: 24px;
      font-size: 1.5em;
      align-self: flex-end;
    }
    ${SIZES.mobile} {
      width: 100%;
    }
  }
`

export default function WelcomePage() {
  return (
    <Page>
      <Row noGutters className="p-3">
        <Col md={{ span: 8, offset: 2 }}>
          <img src={logo} width="20%" alt="AMD"/>
          <div>
            <h3 className="title mt-3">Welcome</h3>
            <p>To Sanofi Virtual Conference</p>
          </div>
          <Row noGutters className="mt-5">
            <Col className="details" md={6}>
              <strong>
                <em>Sanofi-Aventis Singapore Pte. Ltd.</em>
              </strong>
              <p>38 Beach Road #18-11, Singapore 189767</p>
              <p>Tel: 65 6226 3836 Fax: 65 6535 5836</p>
              <p>www.sanofi.com.sg</p>
            </Col>
            <Col className="details" md={6}>
              <strong>
                <em>Sanofi-Aventis Singapore Pte. Ltd.</em>
              </strong>
              <p>Unit TB-18-1, Level 18, Tower B, Plaza 33,<br/>
              1, Jalan Kemajuan, Seksyen 13, 46200 Petaling Jaya, Selangor</p>
              <p>Tel: +603 7651 0800 Fax: +603 7651 0801 / 0802</p>
              <p>www.sanofi.com.my</p>
            </Col>
          </Row>
          <strong>For Healthcare professionals only.</strong>
          <Button variant="default" as={Link} to={routes.login}>
            Login
            <div className="arrow">⇀</div>
          </Button>
        </Col>
      </Row>
    </Page>
  )
}
