import React from 'react'
import styled from 'styled-components'
import { Switch, Route } from 'react-router-dom'
import logo from '../images/logo.png'
import WelcomePage from '../pages/WelcomePage'
import LoginPage from '../pages/LoginPage'
import config from '../config'
import { SIZES } from '../styles/theme'
import routes from '../routes'

const Page = styled.div`
  background-image: linear-gradient(rgba(82, 92, 163, 1), rgba(82, 92, 163, 1)),
    url(${config.assets.entrance.landscape});
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  ${SIZES.mobile} {
    .content {
      display:none;
    }
  }
  .content {
    text-align: center;
    padding-top: 1.5em;
    height: 50%;
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0)
    );
  }
`

export default function EntrancePages({ children }) {
  return (
    <Page>
      <div className="content">
        <img src={logo} width="15%" alt="sanofi" />
      </div>
      <Switch>
        <Route exact path={routes.root} component={WelcomePage} />
        <Route path={routes.login} component={LoginPage} />
      </Switch>
    </Page>
  )
}
