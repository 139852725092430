import React, { Suspense, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { isMobile } from 'mobile-device-detect'
import amplitude from 'amplitude-js';

import config from './config'
import routes from './routes'
import LoadIcon from './components/LoadIcon'

import ProgrammeModal from './components/ProgrammeModal'
import EntrancePages from './containers/EntrancePages'
import PreRegisterPage from './pages/registration/PreRegisterPage'
import RegisterFormPage from './pages/registration/RegisterFormPage'
import PostRegisterPage from './pages/registration/PostRegisterPage'
import ResetPasswordPage from './pages/registration/ResetPasswordPage'
import CountdownPage from './pages/CountdownPage'
import WelcomePage from './pages/WelcomePage'

const WebOnlyComponents = React.lazy(() => import('./webOnlyPages'))
const MobileOnlyComponents = React.lazy(() => import('./mobileOnlyPages'))

function App() {
  const { currentUser, isAuthenticated } = useSelector((state) => state.auth)

  useEffect(() => {
    document.title = config.eventName
    amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY);
  }, [])

  useEffect(() => {
    if (currentUser) {
      amplitude.getInstance().setUserId(currentUser.email);
      amplitude.getInstance().setUserProperties({...currentUser});
    }
  }, [currentUser])

  return useMemo(() => {
    return (
      <>
        <ProgrammeModal />
        <Switch>
          {/* Registration */}
          <Route exact path={routes.preRegister} component={PreRegisterPage} />
          <Route exact path={routes.register} component={RegisterFormPage} />
          <Route
            exact
            path={routes.postRegister}
            component={PostRegisterPage}
          />
          <Route
            exact
            path={routes.resetPassword}
            component={ResetPasswordPage}
          />

          {/* Entrance / Login Pages */}
          <Route exact path={routes.login} component={EntrancePages} />
          <Route exact path={routes.root} component={WelcomePage} />

          {/* Lazy load mobile or desktop routes */}
          <Suspense
            fallback={<LoadIcon style={{ position: 'fixed', zIndex: 999 }} />}
          >
            {isMobile ? (
              <MobileOnlyComponents
                currentUser={currentUser}
                isAuthenticated={isAuthenticated}
              />
            ) : (
              <WebOnlyComponents
                currentUser={currentUser}
                isAuthenticated={isAuthenticated}
              />
            )}
          </Suspense>
        </Switch>
      </>
    )
  }, [currentUser, isAuthenticated])
}

export default App
