import React, { useState } from 'react'
import styled from 'styled-components'

import { COLORS, SIZES } from '../styles/theme'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser, registerUser } from '../ducks/auth'
import { Redirect, Link } from 'react-router-dom'
import ForgetPassword from '../components/ForgetPassword'
import { Button } from 'react-bootstrap'
import routes from '../routes'

const LoginBox = styled.section`
  position: fixed;
  bottom: 0;
  right: 5vw;
  padding: 6vw;
  padding-bottom: 0;
  max-height: 100%;
  overflow: scroll;

  background-color: #F0E9FF;
  text-align: center;
  color: #3C3C3C;
  font-style: italic;

  .action-text {
    font-weight: bold;
    color: ${COLORS.primary};
    cursor: pointer;

    &:hover {
      color: ${COLORS.primaryTrans};
    }
  }

  form {
    padding: 30px 10px;
    margin: auto;
    margin-top: 30px;
    max-width: 400px;

    input {
      letter-spacing: 1px;
      width: 80%;
      background: #F8F4FF;
      border: 1px solid #ACAAB7;
      padding: 12px 32px;
      border-radius: 25px;
      margin-top: 20px;

      &::placeholder {
        color: ${COLORS.lightGray1};
      }
    }

    #tel-input {
      position: relative;

      input {
        margin-top: 0;
        padding-left: 80px;
      }

      .tel-prefix {
        letter-spacing: 1px;
        color: black;
        position: absolute;
        left: 70px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .forget-pass {
      width: 80%;
      color: ${COLORS.primaryTrans};
      margin-top: 10px;
      font-size: 0.9em;
    }

    .login-btn {
      width: 80%;
      display: flex;
      font-style: italic ;
      justify-content: space-between;
      margin: 0 auto;
      margin-top: 20px;
      font-size: 1.2em;
      color: white;
      font-weight: bold;
      padding: 12px 32px;
      border-radius: 56px;
      letter-spacing: 1.5px;
      background: #525CA3;
      &:disabled {
        cursor: not-allowed;
      }
      div.arrow {
        line-height: 24px;
        font-size: 1.5em;
        align-self: flex-end;
      }
    }
  }
  ${SIZES.mobile} {
    position: static;
    padding: 20% 10px;
    min-height: 100vh;
    max-height: auto;
    background-position: center;
  }
`

export default function LoginPage() {
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [loginPage, setLoginPage] = useState(true)

  const { isFetching, isAuthenticated } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const switchForm = () => {
    setLoginPage(!loginPage)
    setPassword('')
  }

  const handleLogin = (e) => {
    e.preventDefault()
    dispatch(
      loginUser({
        email,
        password,
      })
    )
  }

  const handleRegister = (e) => {
    e.preventDefault()
    dispatch(
      registerUser({
        phone,
        email,
        password,
      })
    )
  }

  const onPhoneInput = (e) => {
    const { value } = e.target
    if (isNaN(value)) return
    setPhone(e.target.value)
  }

  const onPasswordInput = (e) => {
    setPassword(e.target.value)
  }

  const onEmailInput = (e) => {
    setEmail(e.target.value.toLowerCase())
  }

  if (isAuthenticated) {
    return <Redirect to={routes.lobby} />
  } else {
    return (
      <LoginBox>
        <h3>Welcome</h3>
        {loginPage ? (
          <>
            <p>Enter your email to login</p>
            <form onSubmit={handleLogin}>
              <input
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={onEmailInput}
              />
              <input
                type="password"
                value={password}
                placeholder="Password"
                onChange={onPasswordInput}
              />
              <Button 
                disabled={!(email.length && password.length) || isFetching}
                type="submit"
                className="btn login-btn"
                variant="default" 
                onSubmit={handleLogin}
              >
                {isFetching ? 'LOGGING IN...' : 'Submit'}
                <div className="arrow">⇀</div>
              </Button>

              <ForgetPassword>
                <span className="action-text">Forgot Password</span>
              </ForgetPassword>

              {/*** Redirect to Sign Up Page ***/}
              <p className="text-muted mt-3">
                Don't have account?{' '}
                <Link to={routes.preRegister} className="action-text">
                  Sign Up
                </Link>
              </p>

              {/*** Switch to Sign Up Form ***/}
              {/* <p className="text-muted mb-1">
                Don't have account?{' '}
                <span onClick={switchForm} className="action-text">
                  Sign Up
                </span>
              </p> */}
            </form>
          </>
        ) : (
          <>
            <p>Please sign up here</p>
            <form onSubmit={handleRegister}>
              <div id="tel-input">
                <div className="tel-prefix">+60</div>
                <input
                  type="tel"
                  placeholder="12345678"
                  value={phone}
                  onChange={onPhoneInput}
                />
              </div>
              <input
                type="email"
                placeholder="E-mail"
                value={email}
                onChange={onEmailInput}
              />
              <input
                type="password"
                value={password}
                placeholder="Password"
                onChange={onPasswordInput}
              />
              <input
                disabled={
                  !(email.length && phone.length && password.length) ||
                  isFetching
                }
                type="submit"
                value={isFetching ? 'REGISTERING...' : 'REGISTER'}
                className="btn login-btn"
              />
              <p className="text-muted">
                Already have an account?{' '}
                <span onClick={switchForm} className="action-text">
                  Log In
                </span>
              </p>
            </form>
          </>
        )}
      </LoginBox>
    )
  }
}
