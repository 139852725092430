import Logo from './images/logo.png'
import AuditoriumM from './images/backdrops/auditoriumMobile.jpg'
import AuditoriumL from './images/backdrops/auditoriumLandscape.jpg'
import Entrance from './images/backdrops/entrance.jpg'
import EntranceMobile from './images/backdrops/entranceMobile.jpg'
import Lobby from './images/backdrops/lobbyLandscape.jpg'
import LobbyMobile from './images/backdrops/lobbyMobile.jpg'
import Booth from './images/backdrops/boothLandscape.jpg'
import BoothMobile from './images/backdrops/boothMobile.jpg'
import LobbyMusic from './images/audio/lobby.mp3'
import { SIZES } from './styles/theme'
import routes from './routes'

// make sure no trailing slash
const dev = {
  apiUrl: 'http://localhost:3000',
}

// make sure no trailing slash
const prod = {
  apiUrl: 'https://vtesaferoad.herokuapp.com',
}

const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev

// if there is no portrait asset, just use landscape asset as portrait property value
const assets = {
  lobbyMusic: LobbyMusic,
  liveUrl: "https://vimeo.com/event/551726/embed",
  countdown: {
    landscape: Entrance,
    portrait: EntranceMobile,
  },
  booth: {
    landscape: Booth,
    portrait: BoothMobile,
  },
  logo: {
    landscape: Logo,
    portrait: Logo,
  },
  entrance: {
    landscape: Entrance,
    portrait: EntranceMobile,
  },
  lobby: {
    landscape: Lobby,
    portrait: LobbyMobile,
  },
  auditorium: {
    landscape: AuditoriumL,
    portrait: AuditoriumM,
  },
  exhibitionHall: {
    landscape: null,
    portrait: null,
  },
}

const layout = {
  auditoriumVid: `
    top: 9.2%;
    bottom: 29%;
    left: 22.3%;
    right: 22.4%;
    ${SIZES.mobile} {
      top: 16.2%;
      bottom: 52%;
      left: 8.1%;
      right: 10.0%;
    }
  `,
}

const lobbyEntries = [
  {
    id: 'auditorium',
    // pinText: 'Enter Auditorium',
    // overlayText: '',
    destination: routes.auditorium1,
    css: `
      top: 58.2%;
      left: 29.8%;
      right: 57%;
      bottom: 36.6%;
      ${SIZES.mobile} {
        top: 50.5%;
        left: 12.3%;
        right: 58.5%;
        bottom: 44.2%;
      }
    `,
  },
  {
    id: 'cloudflix',
    // pinText: 'Enter Video Library',
    destination: routes.cloudflix,
    css: `
      top: 56.9%;
      left: 57.4%;
      right: 32.4%;
      bottom: 36.9%;
      ${SIZES.mobile} {
        top: 50.9%;
        left: 63%;
        right: 13.9%;
        bottom: 44.5%;
      }
    `,
  },
]

export default {
  assets,
  layout,
  lobbyEntries,

  eventName: 'VTE Safe Road',
  googleAnalyticsID: 'UA-180732110-3',
  gsheetQuestionApi:
    'https://script.google.com/macros/s/AKfycbzWqe4JgaKmJ2ukTwG0V-vp5TBCg2VaPeTIhQTWjRDCaUogdzI/exec',

  ...config,
}
