import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import ReactGA from 'react-ga'

import { SIZES } from '../../styles/theme'

import logo from '../../images/logo.png'
import clexaneLogo from '../../images/registration/ClexaneLogo.png'
import BgImg from '../../images/backdrops/entrance.jpg'

const Page = styled.div`
  min-height: 100vh;
  background-image: linear-gradient(
      rgba(82, 92, 163, 1),
      rgba(82, 92, 163, 1)
    ),
    url(${BgImg});
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-size: cover;
  background-position:left;
  color: #fff5e2;
  padding: 2.5em 0;
  ${SIZES.mobile} {
    background-position: 43% 50%;
    padding: 0;
    padding-bottom: 2.5em;
    font-size: 1.1em;
    color: #C3A97A;
    .content {
      background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.7),
        rgba(255, 255, 255, 0.4)
      );
    }
    .content2 {
      background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.4),
        rgba(255, 255, 255, 0)
      );
    }
  }
  .divider {
    border-bottom: 2px solid #C3A97A;
    margin: 24px auto;
    width: 80%;
  }
  header {
    text-align: center;
    font-style: italic;
    h3 {
      font-weight: 600;
    }
  }
  .row {
    .col-md-4 {
      img {
        width: 50%;
        display:block;
        margin: 8px auto;
      }
      h6 {
        color: #FFFFFF;
        text-align: center;
        margin: 16px;
        font-weight: 600;
        font-style: italic;
        ${SIZES.mobile} {
          margin: 8px;
        }
      }
      .details {
        color: #FFFFFF;
        text-align: center;
        margin-bottom: 12px;
        p {
          font-size: 0.9em;
          margin: 12px;
        }
      }
    }
    .register-form {
      border-radius: 48px;
      background: #fcfbf9;
      background: linear-gradient(
        180deg,
        rgba(252, 251, 249, 1) 0%,
        rgba(88, 97, 162, 0.79) 100%
      );
      border: 1px solid #525CA3;
      padding: 3em;
      min-height: 65vh;
      color: #2f2651;
      text-align: center;
      font-style: italic;
      ${SIZES.mobile} {
        background: transparent;
        border: none;
        min-height: 0;
        padding: 1em;
        color: #FFF5E2;
      }
    }
  }
`

export default function PostRegisterPage() {
  useEffect(() => {
    ReactGA.pageview('/post-register-page')
  }, [])

  return (
    <Page>
      <header className="d-none d-md-block">
        <h3>Registration</h3>
        <p>Take a moment to fill up the registration form</p>
      </header>
      <Row noGutters>
        <Col className="content" md={4}>
          <img src={logo} alt="sanofi" />
          <img src={clexaneLogo} alt="clexane" />
          <div className="divider d-block d-md-none"></div>
        </Col>
        <Col className="content2"  md={4}>
          <div className="register-form">
            <h4>Thank You!</h4>
            <p>A confirmation e-mail will be sent to you within 2-3 working days.</p>
          </div>
        </Col>
        <div className="divider d-block d-md-none"></div>
        <Col md={4}>
          <Row noGutters>
            <Col className="details" xs={12}>
              <strong>
                <em>Sanofi-Aventis Singapore Pte. Ltd.</em>
              </strong>
              <p>38 Beach Road #18-11, Singapore 189767</p>
              <p>Tel: 65 6226 3836 Fax: 65 6535 5836</p>
              <p>www.sanofi.com.sg</p>
            </Col>
            <Col className="details" xs={12}>
              <strong>
                <em>Sanofi-Aventis Singapore Pte. Ltd.</em>
              </strong>
              <p>Unit TB-18-1, Level 18, Tower B, Plaza 33,<br/>
              1, Jalan Kemajuan, Seksyen 13, 46200 Petaling Jaya, Selangor</p>
              <p>Tel: +603 7651 0800 Fax: +603 7651 0801 / 0802</p>
              <p>www.sanofi.com.my</p>
            </Col>
          </Row>
          <h6>For Healthcare professionals only.</h6>
        </Col>
      </Row>
    </Page>
  )
}
