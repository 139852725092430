export default {
  root: '/',

  preRegister: '/register',
  register: '/register/form',
  postRegister: '/register/complete',
  resetPassword: '/register/create-password',

  login: '/login',

  lobby: '/lobby',
  networking: '/networking-lounge',
  cloudflix: '/highlights',

  auditorium1: '/auditorium-hall-1',
  auditorium2: '/auditorium-hall-2',
  auditorium3: '/auditorium-hall-3',

  exhibition1: '/exhibition-hall-1',
  exhibition2: '/exhibition-hall-2',
  exhibition3: '/exhibition-hall-3',
}
