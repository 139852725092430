const TOGGLE_MUTE = 'TOGGLE_MUTE'

// Q&A Form Modal in auditorium
const TOGGLE_QUESTION_FORM = 'TOGGLE_QUESTION_FORM'

// Programme Schedule Modal
const SHOW_SCHEDULE = 'SHOW_SCHEDULE'
const HIDE_SCHEDULE = 'HIDE_SCHEDULE'

const initialState = {
  showForm: false,
  muted: false,
  scheduleModal: false,
}

export default function layoutReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MUTE:
      return {
        ...state,
        muted: !state.muted,
      }

    case TOGGLE_QUESTION_FORM:
      return {
        ...state,
        showForm: !state.showForm,
      }

    case SHOW_SCHEDULE:
      return {
        ...state,
        scheduleModal: true,
      }

    case HIDE_SCHEDULE:
      return {
        ...state,
        scheduleModal: false,
      }

    default:
      return state
  }
}

export function toggleMute() {
  return { type: TOGGLE_MUTE }
}

export function toggleShowForm() {
  return { type: TOGGLE_QUESTION_FORM }
}

export function showSchedule() {
  return { type: SHOW_SCHEDULE }
}
