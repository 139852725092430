import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'

import { SIZES } from '../../styles/theme'

import logo from '../../images/logo.png'
import clexaneLogo from '../../images/registration/ClexaneLogo.png'
import BgImg from '../../images/backdrops/entrance.jpg'
import Axios from 'axios'
import config from '../../config'
import { toast } from 'react-toastify'
import routes from '../../routes'
import queryString from 'query-string'

const Page = styled.div`
  min-height: 100vh;
  background-image: linear-gradient(
      rgba(82, 92, 163, 0.94),
      rgba(82, 92, 163, 0.94)
    ),
    url(${BgImg});
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-size: cover;
  background-position:left;
  color: #fff5e2;
  padding: 2.5em 0;
  ${SIZES.mobile} {
    padding: 0;
    padding-bottom: 2.5em;
    font-size: 1.1em;
    color: #C3A97A;
  }
  .divider {
    border-bottom: 2px solid #C3A97A;
    margin: 24px auto;
    width: 80%;
  }
  header {
    text-align: center;
    font-style: italic;
    h3 {
      font-weight: 600;
    }
  }
  .row {
    .col-md-4 {
      img {
        width: 50%;
        display:block;
        margin: 8px auto;
      }
      h6 {
        color: #FFFFFF;
        text-align: center;
        margin: 16px;
        font-weight: 600;
        font-style: italic;
        ${SIZES.mobile} {
          margin: 8px;
        }
      }
      .details {
        color: #FFFFFF;
        text-align: center;
        margin-bottom: 8px;
        p {
          font-size: 0.9em;
          margin: 12px;
        }
      }
    }
    .register-form {
      border-radius: 48px;
      background: #fcfbf9;
      background: linear-gradient(
        180deg,
        rgba(252, 251, 249, 1) 0%,
        rgba(88, 97, 162, 0.79) 100%
      );
      border: 1px solid #525CA3;
      padding: 2.5em;

      .form-group {
        width: 100%;
        margin-bottom: 8px;
        label {
          color: #2f2651;
          font-weight: 600;
          font-style: italic;
          padding: 0 8px;
          padding-right: 0;
          font-size: 13px;
        }
        .form-input {
          display: block;
          color: #2f2651;
          letter-spacing: 1px;
          width: 100%;
          background: transparent;
          border: 1px solid #E3D5BA;
          padding: 4px 16px;
          border-radius: 25px;
          outline: none;
        }
      }
    }
  }
  button.submit-btn {
    background: #525CA3;
    width: 100%;
    color: #2f2651;
    font-weight: 500;
    font-style: italic;
    border: 1px solid #0A2A53;
    border-radius: 56px;
    display: flex;
    justify-content: space-between;
    padding: 0px 24px;
    margin: 0 auto;
    font-size: 0.8em;
    outline: none;
    box-shadow: none;
    ${SIZES.mobile} {
      width: 100%;
      padding: 4px 24px;
    }
    &:disabled {
      cursor: not-allowed;
      background: #c3c3c3;
      border: solid 1px #707070;
    }
    :not(:disabled):hover {
      background: #0A2A53;
      border: 1px solid #0A2A53;
      color: #fff5e2;
    }
    div {
      line-height: 32px;
    }
    div.arrow {
      line-height: 24px;
      font-size: 1.5em;
      align-self: flex-end;
    }
  }
`

export default function ResetPasswordPage() {
  const location = useLocation()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    Axios.post(
      `${config.apiUrl}/api/v1/create_password`,
      {
        password,
      },
      {
        headers: {
          Authorization: `Bearer ${queryString.parse(location.search).token}`,
        },
      }
    )
      .then(() => {
        setLoading(false)
        toast('Your password is successfully created!')
        history.push(routes.login)
      })
      .catch((err) => {
        setLoading(false)
        toast(err.response.data.message)
      })
  }

  const hasEmptyField = !(
    password.length >= 8 &&
    passwordConfirmation.length &&
    password === passwordConfirmation
  )

  return (
    <Page>
      <header className="px-5 pt-5 pb-3 py-md-5">
        <h3>Reset Password</h3>
      </header>
      <Row noGutters>
        <Col className="d-none d-md-block" md={4}>
          <img src={logo} alt="sanofi" />
          <img src={clexaneLogo} alt="clexane" />
        </Col>
        <Col md={4}>
          <Form onSubmit={handleSubmit}>
            <Row className="register-form py-5" noGutters>
              <Form.Group>
                <label>Create New Password* (min. 8 characters)</label>
                <input
                  className="form-input"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <label>Password Confirmation</label>
                <input
                  className="form-input"
                  type="password"
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
              </Form.Group>
              <div className="w-100 mt-3">
                {hasEmptyField && (
                  <p className="text-danger text-right">
                    <em>
                      <small>Please make sure both password matches</small>
                    </em>
                  </p>
                )}
                <Button
                  type="submit"
                  className="submit-btn"
                  variant="default"
                  disabled={hasEmptyField || loading}
                  onSubmit={handleSubmit}
                >
                  <div>{loading ? 'Loading...' : 'Create Password'}</div>
                  <div className="arrow">⇀</div>
                </Button>
              </div>
            </Row>
          </Form>
        </Col>
        <div className="divider d-block d-md-none"></div>
        <Col md={4}>
          <Row noGutters>
            <Col className="details" xs={12}>
              <strong>
                <em>Sanofi-Aventis Singapore Pte. Ltd.</em>
              </strong>
              <p>38 Beach Road #18-11, Singapore 189767</p>
              <p>Tel: 65 6226 3836 Fax: 65 6535 5836</p>
              <p>www.sanofi.com.sg</p>
            </Col>
            <Col className="details" xs={12}>
              <strong>
                <em>Sanofi-Aventis Singapore Pte. Ltd.</em>
              </strong>
              <p>Unit TB-18-1, Level 18, Tower B, Plaza 33,<br/>
              1, Jalan Kemajuan, Seksyen 13, 46200 Petaling Jaya, Selangor</p>
              <p>Tel: +603 7651 0800 Fax: +603 7651 0801 / 0802</p>
              <p>www.sanofi.com.my</p>
            </Col>
          </Row>
          <h6>For Healthcare professionals only.</h6>
        </Col>
      </Row>
    </Page>
  )
}
