import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import config from '../config'
import { COLORS, SIZES } from '../styles/theme'

const Page = styled.div`
  background-image: linear-gradient(180deg, #ffffff, #d1d0d0);
  min-height: 100vh;
  width: 100vw;

  text-align: center;

  #header {
    background-image: linear-gradient(
      180deg,
      rgba(82, 92, 163, 1),
      rgba(7, 10, 25, 1)
    );
    padding-top: 3vh;

    h1 {
      color: white;
      font-weight: 600;
      letter-spacing: 1px;
      margin-bottom: 0.8em;
      font-size: 3rem;
    }

    .time-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      text-transform: uppercase;

      .time-card {
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        background: rgb(255, 255, 255);
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(242, 241, 241, 1) 70%,
          rgba(180, 179, 179, 1) 100%
        );

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        min-width: 120px;
        width: 10vw;
        margin: 0 1%;
        margin-top: 5px;
        padding-top: 10px;

        p {
          margin-bottom: 6px;
          font-weight: 500;
        }

        h2 {
          font-weight: 700;
          font-size: 3.2rem;
        }
      }
    }
  }

  img {
    object-fit: cover;
    min-height: calc(100vh - 224px);
    width: 100vw;
    &#bg-portrait {
      display: none;
    }

    ${SIZES.mobile} {
      &#bg-landscape {
        display: none;
      }
      &#bg-portrait {
        display: inline;
      }
    }
  }
`

export default function CountdownPage() {
  const calculateTimeLeft = () => {
    // year, actual month - 1, day, hour, minute, second, ignore
    const difference = +new Date(2020, 10, 28, 14, 0, 0, 0) - +new Date()
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      }
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    localStorage.clear()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)
  })

  const timerComponents = []

  Object.keys(timeLeft).forEach((interval) => {
    if (isNaN(timeLeft[interval])) {
      return
    }

    timerComponents.push(
      <div className="time-card">
        <p>{interval}</p>
        <h2>{timeLeft[interval]}</h2>
      </div>
    )
  })

  return (
    <Page>
      <div id="header">
        <h1>COMING SOON</h1>
        <div className="time-wrapper">
          {timerComponents.length ? timerComponents : <span>Time's up!</span>}
        </div>
      </div>
      <img src={config.assets.countdown.landscape} id="bg-landscape" alt="background" />
      <img src={config.assets.countdown.portrait} id="bg-portrait" alt="background" />
    </Page>
  )
}
